import cleanDeep from 'clean-deep'
import { merge } from 'merge-anything'
import {
  applySnapshot,
  getParent,
  getSnapshot,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { Leads } from './Leads'

export const LeadsFilters = types
  .model('LeadsFilters', {
    category: types.array(types.string),
    role: types.maybe(types.string),
    agent: types.array(types.string),
    status: types.array(types.string),
  })
  .views(self => ({
    format() {
      return cleanDeep(getSnapshot(self))
    },
  }))
  .actions(self => ({
    save(values: SnapshotIn<typeof self>) {
      applySnapshot(self, merge(getSnapshot(self), values))
      getParent<typeof Leads>(self).setPage(1)
    },
  }))
