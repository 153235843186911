import gql from 'graphql-tag'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'

export const PROPERTY_VIDEO_QUERY = gql`
  query PropertyVideo($uuid: ID!, $videoUuid: ID!) {
    propertyVideo(uuid: $uuid, videoUuid: $videoUuid)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/videos/{args.videoUuid}"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const CREATE_PROPERTY_VIDEO_MUTATION = gql`
  mutation CreatePropertyVideo($uuid: ID!, $input: CreatePropertyVideoInput!) {
    createPropertyVideo(uuid: $uuid, input: $input)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/videos"
        method: "POST"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const UPDATE_PROPERTY_VIDEO_MUTATION = gql`
  mutation UpdatePropertyVideo(
    $uuid: ID!
    $videoUuid: ID!
    $input: UpdatePropertyVideoInput!
  ) {
    updatePropertyVideo(uuid: $uuid, videoUuid: $videoUuid, input: $input)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/videos/{args.videoUuid}"
        method: "POST"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const DESTROY_PROPERTY_VIDEO_MUTATION = gql`
  mutation DestroyPropertyVideo($uuid: ID!, $videoUuid: ID!) {
    destroyPropertyVideo(uuid: $uuid, videoUuid: $videoUuid)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/videos/{args.videoUuid}"
        method: "DELETE"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`
