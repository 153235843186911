import styled from '@emotion/styled'

export const TextPlaceholder = styled.div<{
  width: number | string
  inline?: boolean
}>`
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
  height: 15px;
  background-color: #e5e8ec;
  vertical-align: middle;
`
