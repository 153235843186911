import { observer } from 'mobx-react-lite'
import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

function getPagingRange(
  current: number,
  { min = 1, total = 20, length = 5 } = {},
) {
  if (length > total) length = total

  let start = current - Math.floor(length / 2)
  start = Math.max(start, min)
  start = Math.min(start, min + total - length)

  return Array.from({ length: length }, (el, i) => start + i)
}

export const Paginator = observer<{
  total: number
  currentPage: number
  perPage: number
  loading: boolean
  onChange: (page: number) => void
}>(({ total, currentPage, perPage, loading, onChange }) => {
  const totalPages = Math.ceil(total / perPage)
  const pages = getPagingRange(currentPage, { total: totalPages, length: 7 })

  return (
    <div className="paginator">
      <Pagination listClassName="mb-0 justify-content-center">
        <PaginationItem>
          <PaginationLink onClick={() => onChange(1)} first />
        </PaginationItem>
        {pages.map(page => (
          <PaginationItem key={page} active={page === currentPage}>
            <PaginationLink disabled={loading} onClick={() => onChange(page)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationLink onClick={() => onChange(totalPages)} last />
        </PaginationItem>
      </Pagination>
    </div>
  )
})
