import cx from 'classnames'
import React, { ComponentType } from 'react'
import { ButtonGroup, ButtonGroupProps } from 'reactstrap'

export const ButtonCheckGroup: ComponentType<ButtonGroupProps & {
  invalid?: boolean
}> = ({ className, invalid, children, props }) => {
  return (
    <ButtonGroup className={cx('d-flex', className, { invalid })} {...props}>
      {children}
    </ButtonGroup>
  )
}
