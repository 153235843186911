import gql from 'graphql-tag'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'
import { USER_DETAIL_FRAGMENT } from './users'

export const PROPERTY_DETAIL_FRAGMENT = gql`
  fragment PropertyDetail on Property {
    uuid @export(as: "uuid")
    name
    principalNotes
    description
    amount
    comments
    role
    seoTitle
    seoDescription
    seoTags
    pin
    promoted
    currency
    visible
    status
    isArchived
    availability
    availableAt
    privateNotes
    visits
    favorites
    shares
    leads
    createdAt
    updatedAt
    promotion @type(name: "Promotion") {
      uuid
      name
    }
    category @type(name: "Category") {
      uuid
      name
    }
    categories @type(name: "Category") {
      uuid
      name
    }
    rent @type(name: "Rent") {
      monthPrice
      agreementPrice
    }
    sale @type(name: "Sale") {
      price
      advancePayment
      advanceValidity
      downPayment
      balanceType
      other
      installments
      financingNotes
      financing @type(name: "Financing") {
        uuid
        name
      }
    }
    area @type(name: "Area") {
      total
      width
      depth
      built
      type
    }
    places @type(name: "Places") {
      floors
      rooms
      bathrooms
      halfBathrooms
      parkingSpacesCeiling
      parkingSpacesNoCeiling
    }
    measurements @type(name: "Measurements") {
      built
      improved
    }
    furnishings @type(name: "Furnishing") {
      uuid
      name
    }
    services @type(name: "Service") {
      uuid
      name
    }
    amenities @type(name: "Amenity") {
      uuid
      name
    }
    location @type(name: "Location") {
      type
      coordinates
    }
    zone @type(name: "Zone") {
      uuid
      name
    }
    address @type(name: "Address") {
      street
      exterior
      interior
      city @type(name: "City") {
        uuid
        name
      }
      neighborhood @type(name: "Neighborhood") {
        uuid
        name
      }
      development @type(name: "Development") {
        uuid
        name
      }
    }
    contact @type(name: "Contact") {
      firstName
      lastName
      birthDate
      homePhone
      officePhone
      mobilePhone
      email
      address
      comments
    }
    managers @type(name: "User") {
      uuid
      firstName
      lastName
    }
    owner @type(name: "User") {
      ...UserDetail
    }
    gallery @type(name: "PropertyMedia") {
      ...PropertyMediaDetail
    }
    files @type(name: "PropertyFile") {
      uuid
      publicUrl
      secureUrl
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
  ${USER_DETAIL_FRAGMENT}
`

export const PROPERTIES_QUERY = gql`
  query Properties($query: Object) {
    properties(query: $query)
      @rest(type: "PropertiesList", path: "/admin/properties?{args.query}") {
      total
      data {
        ...PropertyDetail
      }
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
`

export const PROPERTY_QUERY = gql`
  query Property($uuid: ID!) {
    property(uuid: $uuid)
      @rest(type: "Property", path: "/admin/properties/{args.uuid}") {
      ...PropertyDetail
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
`

export const CREATE_PROPERTY_MUTATION = gql`
  mutation CreateProperty($input: CreatePropertyInput!) {
    createProperty(input: $input)
      @rest(type: "Property", path: "/admin/properties", method: "POST") {
      uuid
    }
  }
`

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation UpdateProperty($uuid: ID!, $input: UpdatePropertyInput!) {
    updateProperty(uuid: $uuid, input: $input)
      @rest(
        type: "Property"
        path: "/admin/properties/{args.uuid}"
        method: "POST"
      ) {
      uuid
    }
  }
`

export const DESTROY_PROPERTY_MUTATION = gql`
  mutation DestroyProperty($uuid: ID!) {
    destroyProperty(uuid: $uuid)
      @rest(
        type: "Property"
        path: "/admin/properties/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
    }
  }
`

export const PUBLISH_PROPERTY_MUTATION = gql`
  mutation PublishProperty($uuid: ID!, $input: Object!) {
    publishProperty(uuid: $uuid, input: $input)
      @rest(
        type: "Property"
        path: "/admin/properties/{args.uuid}/publish"
        method: "POST"
      ) {
      uuid
    }
  }
`

export const ARCHIVE_PROPERTY_MUTATION = gql`
  mutation ArchiveProperty($uuid: ID!, $input: Object!) {
    archiveProperty(uuid: $uuid, input: $input)
      @rest(
        type: "Property"
        path: "/admin/properties/{args.uuid}/archive"
        method: "POST"
      ) {
      uuid
    }
  }
`

export const UNARCHIVE_PROPERTY_MUTATION = gql`
  mutation UnarchiveProperty($uuid: ID!, $input: Object!) {
    unarchiveProperty(uuid: $uuid, input: $input)
      @rest(
        type: "Property"
        path: "/admin/properties/{args.uuid}/unarchive"
        method: "POST"
      ) {
      uuid
    }
  }
`
