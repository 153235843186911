import { useQuery } from '@apollo/react-hooks'
import { ComponentType, ReactElement } from 'react'
import { CITIES_QUERY } from '~graphql'
import { CitiesQuery, CitiesQueryVariables, CityDetailFragment } from '~types'
import { sortByName } from '~utils'

export const UseCities: ComponentType<{
  children: (cities: CityDetailFragment[]) => ReactElement | null
}> = ({ children }) => {
  const { data } = useQuery<CitiesQuery, CitiesQueryVariables>(CITIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })
  return children(data && data.cities ? sortByName(data.cities.data) : [])
}
