import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { RestLink } from 'apollo-link-rest'
import get from 'lodash/get'
import { objectToFormData } from 'object-to-formdata'

const cache = new InMemoryCache()

const authLink = setContext((operation, { headers }) => {
  const token = localStorage.getItem('token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const restLink = new RestLink({
  uri: process.env.API_URL,
  bodySerializers: {
    upload: (body, headers) => {
      headers.delete('Content-Type')
      return { body: objectToFormData(body), headers }
    },
  },
  customFetch: async (request, options) => {
    const response = await fetch(request, options)
    if (response.status === 404) {
      const json = await response.json()
      throw new Error(json.message || response.statusText)
    }
    return response
  },
  typePatcher: {
    LoginPayload: (data: any) => {
      if (data.user) {
        data.user.__typename = 'User'
      }
      return data
    },
    ResetPasswordPayload: (data: any) => {
      if (data.user) {
        data.user.__typename = 'User'
      }
      return data
    },
    PropertiesList: (data: any) => {
      if (data.data) {
        data.data = data.data.map((item: any) => ({
          __typename: 'Property',
          ...item,
        }))
      }
      return data
    },
    PropertyMediasList: (data: any) => {
      if (data.data) {
        data.data = data.data.map((item: any) => ({
          __typename: 'PropertyMedia',
          ...item,
        }))
      }
      return data
    },
    PropertyFilesList: (data: any) => {
      if (data.data) {
        data.data = data.data.map((item: any) => ({
          __typename: 'PropertyFile',
          ...item,
        }))
      }
      return data
    },
    UsersList: (data: any) => {
      if (data.data) {
        data.data = data.data.map((item: any) => ({
          __typename: 'User',
          ...item,
        }))
      }
      return data
    },
  },
})

const errorLink = onError(({ operation, networkError }) => {
  if (operation.operationName === 'JWTCheckup') {
    return
  }

  if (get(networkError, 'response.status') === 401) {
    window.location.replace('/logout')
  }
})

const link = ApolloLink.from([errorLink, authLink, restLink])

export const client = new ApolloClient({ link, cache })

// @ts-ignore
global.client = client
