import gql from 'graphql-tag'

export const METRICS_SUMMARY_QUERY = gql`
  query MetricsSummary($query: Object!) {
    metricsSummary(query: $query)
      @rest(
        type: "MetricsSummary"
        path: "/admin/metrics/summary?{args.query}"
      ) {
      visits
      favorites
      shares
      newLeads
      newConversions
      newProperties
      updatedLeads
    }
  }
`

export const METRICS_PROPERTIES_QUERY = gql`
  query MetricsProperties($query: Object!) {
    metricsProperties(query: $query)
      @rest(
        type: "PropertiesList"
        path: "/admin/metrics/properties?{args.query}"
      ) {
      total
      data @type(name: "Property") {
        uuid
        role
        createdAt
        category @type(name: "Category") {
          uuid
          name
        }
        owner @type(name: "User") {
          uuid
          firstName
          lastName
        }
      }
    }
  }
`

export const METRICS_LEADS_QUERY = gql`
  query MetricsLeads($query: Object!) {
    metricsLeads(query: $query)
      @rest(type: "LeadsList", path: "/admin/metrics/leads?{args.query}") {
      total
      data @type(name: "Lead") {
        uuid
        status
        createdAt
        agent @type(name: "User") {
          uuid
          firstName
          lastName
        }
      }
    }
  }
`

export const PROPERTIES_VISITS_QUERY = gql`
  query PropertiesVisits($query: Object!) {
    propertiesVisits(query: $query)
      @rest(
        type: "PropertiesList"
        path: "/admin/metrics/visits?{args.query}"
      ) {
      total
      data @type(name: "Property") {
        uuid
        role
        visits
        amount
        currency
        category @type(name: "Category") {
          uuid
          name
        }
      }
    }
  }
`

export const RESOURCE_VISITS_QUERY = gql`
  query ResourceVisits($resource: String!, $query: Object!) {
    resourceVisits(resource: $resource, query: $query)
      @rest(
        type: "ResourceVisits"
        path: "/admin/metrics/visits-by-{args.resource}?{args.query}"
      ) {
      name
      items
      percentage
    }
  }
`
