import styled from '@emotion/styled'
import React, { ComponentType, Fragment } from 'react'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'

const Wrapper = styled.div`
  height: 55px;
  background: #fafafc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1015;

  a:last-of-type {
    color: #757d88;
  }
`

export const Breadcrumbs: ComponentType<{
  breadcrumbs: { path: string; label: string }[]
}> = ({ breadcrumbs }) => {
  const location = useLocation()

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            {breadcrumbs
              .map(({ path, label }) => {
                const match = matchPath(location.pathname, { path })

                if (match) {
                  return (
                    <Link key={match.path} to={match.url} title={label}>
                      {label}
                    </Link>
                  )
                }

                return null
              })
              .filter(Boolean)
              .map((item, index) => (
                <Fragment key={index}>
                  {index !== 0 && <span className="px-2 text-muted">/</span>}
                  {item}
                </Fragment>
              ))}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}
