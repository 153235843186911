import { types } from 'mobx-state-tree'
import { PropertiesFilters } from './PropertiesFilters'
import { Sortings } from './Sortings'
import { UploadStat } from './UploadStat'

export const Properties = types
  .model('Properties', {
    page: types.optional(types.number, 1),
    map: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    filters: types.optional(PropertiesFilters, () =>
      PropertiesFilters.create(),
    ),
    sortings: types.optional(Sortings, () => Sortings.create()),
    uploads: types.array(UploadStat),
  })
  .actions(self => ({
    setPage(page: number) {
      self.page = page
    },
    setSearch(search: string) {
      self.search = search
    },
    toggleMap() {
      self.map = !self.map

      if (self.sortings.visible) {
        self.sortings.toggleVisible()
      }
    },
    getOrCreateUpload(id: number | string) {
      const upload = self.uploads.find(upload => upload.id === id)

      if (upload) {
        return upload
      } else {
        const newUpload = UploadStat.create({ id })
        self.uploads.push(newUpload)
        return newUpload
      }
    },
  }))
