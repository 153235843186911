import {
  LeadDetailFragment,
  PropertyDetailFragment,
  UserDetailFragment,
} from '~types'

const UserPermissions = {
  isBroker(user: UserDetailFragment) {
    return user.role === 'broker'
  },
  isManager(user: UserDetailFragment) {
    return user.role === 'manager'
  },
  isAdmin(user: UserDetailFragment) {
    return user.role === 'admin'
  },
  canUpdate(viewer: UserDetailFragment, user: UserDetailFragment) {
    if (this.isAdmin(viewer) || this.isManager(viewer)) {
      return true
    }

    if (viewer.uuid === user.uuid) {
      return true
    }

    return false
  },
  canUpdateStatus(user: UserDetailFragment) {
    return this.isAdmin(user)
  },
  canArchive(user: UserDetailFragment) {
    return this.isAdmin(user)
  },
}

const PropertyPermissions = {
  canUpdate(user: UserDetailFragment, property: PropertyDetailFragment) {
    if (UserPermissions.isAdmin(user)) {
      return true
    } else if (UserPermissions.isManager(user)) {
      return true
    } else if (user.uuid === property.owner.uuid) {
      return true
    }

    return false
  },
  canActivate(user: UserDetailFragment, property: PropertyDetailFragment) {
    if (UserPermissions.isAdmin(user)) {
      return true
    } else if (user.uuid === property.owner.uuid) {
      return true
    } else if (
      UserPermissions.isManager(user) &&
      property.owner.role === 'broker'
    ) {
      return true
    }

    return false
  },
}

const LeadPermissions = {
  canUpdate(user: UserDetailFragment, lead: LeadDetailFragment) {
    if (UserPermissions.isAdmin(user)) {
      return true
    } else if (user.uuid === lead.agent?.uuid) {
      return true
    } else if (
      UserPermissions.isManager(user) &&
      lead.agent?.role === 'broker'
    ) {
      return true
    }

    return false
  },
}

export const permissions = {
  user: UserPermissions,
  property: PropertyPermissions,
  lead: LeadPermissions,
}
