import gql from 'graphql-tag'

export const USER_DETAIL_FRAGMENT = gql`
  fragment UserDetail on User {
    uuid
    firstName
    lastName
    birthdate
    hireddate
    position
    team
    role
    status
    whatsapp
    phone1
    phone2
    phone3
    email
    leads
    properties
    propertiesOnRent
    propertiesOnSale
    personalEmail
    address
    isArchived
    lastLogin
    invitation
    createdAt
    updatedAt
    avatar @type(name: "UserAvatar") {
      publicUrl
      secureUrl
      thumbnailUrl
    }
  }
`

export const VIEWER_QUERY = gql`
  query Viewer($query: Object!) {
    viewer @rest(type: "User", path: "/admin/users/me") {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`

export const USERS_QUERY = gql`
  query Users($query: Object!) {
    users(query: $query)
      @rest(type: "UsersList", path: "/admin/users?{args.query}") {
      total
      data {
        ...UserDetail
      }
    }
  }
  ${USER_DETAIL_FRAGMENT}
`

export const USER_QUERY = gql`
  query User($uuid: ID!) {
    user(uuid: $uuid) @rest(type: "User", path: "/admin/users/{args.uuid}") {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`

export const EMAIL_AVAILABILITY_QUERY = gql`
  query EmailAvailability($email: String!) {
    emailAvailability(email: $email)
      @rest(
        type: "User"
        path: "/admin/users/availability?email={args.email}"
      ) {
      available
    }
  }
`

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
      @rest(
        type: "User"
        path: "/admin/users"
        method: "POST"
        bodySerializer: "upload"
      ) {
      ...UserDetail
    }
  }
  ${USER_DETAIL_FRAGMENT}
`

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($uuid: ID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input)
      @rest(
        type: "User"
        path: "/admin/users/{args.uuid}"
        method: "POST"
        bodySerializer: "upload"
      ) {
      uuid
    }
  }
`

export const ARCHIVE_USER_MUTATION = gql`
  mutation ArchiveUser($uuid: ID!, $input: Object!) {
    archiveUser(uuid: $uuid, input: $input)
      @rest(
        type: "User"
        path: "/admin/users/{args.uuid}/archive"
        method: "POST"
      ) {
      uuid
    }
  }
`

export const UNARCHIVE_USER_MUTATION = gql`
  mutation UnarchiveUser($uuid: ID!, $input: Object!) {
    unarchiveUser(uuid: $uuid, input: $input)
      @rest(
        type: "User"
        path: "/admin/users/{args.uuid}/unarchive"
        method: "POST"
      ) {
      uuid
    }
  }
`
