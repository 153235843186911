import styled from '@emotion/styled'
import React, { ComponentType } from 'react'
import { Link, NavLink } from 'react-router-dom'
import ArrowRightIcon from '~/images/arrow-right-icon.svg'
import ContentIcon from '~/images/content-icon.svg'
import DashboardIcon from '~/images/dashboard-icon.svg'
import HomeIcon from '~/images/home-icon.svg'
import SettingsIcon from '~/images/settings-icon.svg'
import SupportIcon from '~/images/support-icon.svg'
import UserIcon from '~/images/user-icon.svg'
import YPBrand from '~/images/yp-brand.svg'
import { useViewer } from '~hooks'
import { permissions } from '~utils'

const Container = styled.div`
  width: 240px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 50px 30px 25px 30px;
  background: #082c50;
`

const SectionItemNavLink = styled(NavLink)`
  letter-spacing: 1px;
  color: #3d88d2;
  position: relative;
  padding-left: calc(14px + 10px);

  &:hover,
  &.active {
    color: #73b1ed;
    text-decoration: none;

    .icon path {
      fill: #73b1ed;
    }
  }

  .icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-60%);

    path {
      fill: #3d88d2;
    }
  }
`

const SectionItemLink = SectionItemNavLink.withComponent<typeof Link>(Link)

const SectionItemExternalLink = SectionItemNavLink.withComponent<'a'>('a')

const Copyright = styled.div`
  color: rgba(61, 136, 210, 0.5);
  font-size: 10px;
  letter-spacing: 1.67px;
  text-align: center;
`

export const Sidebar: ComponentType = () => {
  const { viewer } = useViewer()

  return (
    <Container className="d-flex flex-column overflow-auto">
      <div className="text-center pb-4">
        <Link to="/" title="Inicio">
          <YPBrand />
        </Link>
      </div>
      <div className="py-3">
        <SectionItemNavLink
          to="/"
          className="d-block text-uppercase font-weight-bold py-1"
          exact
        >
          <DashboardIcon className="icon" /> Dashboard
        </SectionItemNavLink>
      </div>
      <div className="py-3">
        <SectionItemNavLink
          to="/properties"
          className="d-block text-uppercase font-weight-bold py-1"
        >
          <HomeIcon className="icon" /> Propiedades
        </SectionItemNavLink>
        <SectionItemNavLink
          to="/leads"
          className="d-block text-uppercase font-weight-bold py-1"
        >
          <ArrowRightIcon className="icon" /> Leads
        </SectionItemNavLink>
        <SectionItemNavLink
          to="/users"
          className="d-block text-uppercase font-weight-bold py-1"
        >
          <UserIcon className="icon" /> Agentes
        </SectionItemNavLink>
      </div>
      {viewer && permissions.user.isAdmin(viewer) && (
        <div className="py-3">
          <SectionItemExternalLink
            href="https://content.yucatanpremier.com/ghost"
            to="https://content.yucatanpremier.com/ghost"
            className="d-block text-uppercase font-weight-bold py-1"
            target="_blank"
          >
            <ContentIcon className="icon" /> Contenido
          </SectionItemExternalLink>
        </div>
      )}
      {viewer && permissions.user.isAdmin(viewer) && (
        <div className="py-3">
          <SectionItemLink
            to="/settings"
            className="d-block text-uppercase font-weight-bold py-1"
          >
            <SettingsIcon className="icon" /> Configuración
          </SectionItemLink>
          <SectionItemNavLink to="/settings/variables" className="d-block py-1">
            Variables
          </SectionItemNavLink>
          <SectionItemNavLink to="/settings/structure" className="d-block py-1">
            Estructura
          </SectionItemNavLink>
        </div>
      )}
      <div className="py-3">
        <SectionItemNavLink
          to="/support"
          className="d-block text-uppercase font-weight-bold py-1"
        >
          <SupportIcon className="icon" /> Soporte
        </SectionItemNavLink>
      </div>
      <Copyright className="mt-auto text-uppercase pt-3">
        Premier Broker
      </Copyright>
    </Container>
  )
}
