import styled from '@emotion/styled'

export const TabNavigation = styled.div`
  display: flex;
  border-bottom: 1px solid #2d79c3;

  a {
    padding: 17px;
    text-decoration: none;
    text-align: center;
    color: #a2aab4;
    flex: 1;

    &:hover {
      background: #e5e8ec;
    }

    &.active {
      background: #2d79c3;
      color: white !important;
    }
  }
`
