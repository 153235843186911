import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'

interface NameSortable {
  name: string
}

export function sortByName<T extends NameSortable>(sortables: T[]) {
  return sortBy(sortables, [(item: any) => lowerCase(item.name)])
}
