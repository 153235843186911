import { ApolloError } from 'apollo-client'
import get from 'lodash/get'

export const getApolloErrorMessage = (error: ApolloError) => {
  const graphqlMessage = get(error, 'graphQLErrors.0.message')
  const networkMessage = get(error, 'networkError.result.message')
  const networkErrorMessage = get(error, 'networkError.message')
  return (
    graphqlMessage || networkMessage || networkErrorMessage || error.message
  )
}
