import { useQuery } from '@apollo/react-hooks'
import { VIEWER_QUERY } from '~graphql'
import { ViewerQuery, ViewerQueryVariables } from '~types'

export const useViewer = () => {
  const { data, ...props } = useQuery<ViewerQuery, ViewerQueryVariables>(
    VIEWER_QUERY,
  )
  return { viewer: data?.viewer, ...props }
}
