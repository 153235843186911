import styled from '@emotion/styled'

export const ToolbackBackdrop = styled.div`
  position: fixed;
  height: calc(100vh - 70px);
  left: 240px;
  right: 0;
  bottom: 0;
  background: #081928;
  opacity: 0.7;
  z-index: 1000;
`
