export const currency = (
  value: number,
  currency: string | undefined | null = 'MXN',
) => {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: currency === null ? 'MXN' : currency,
    minimumFractionDigits: 0,
  }).format(value)
}
