import gql from 'graphql-tag'

export const NEIGHBORHOOD_DETAIL_FRAGMENT = gql`
  fragment NeighborhoodDetail on Neighborhood {
    uuid
    name
    city @type(name: "City") {
      uuid
      name
    }
  }
`

export const NEIGHBORHOODS_QUERY = gql`
  query Neighborhoods($city: ID) {
    neighborhoods(city: $city)
      @rest(type: "NeighborhoodsList", path: "/admin/neighborhoods?{args}") {
      total
      data @type(name: "Neighborhood") {
        ...NeighborhoodDetail
      }
    }
  }
  ${NEIGHBORHOOD_DETAIL_FRAGMENT}
`

export const NEIGHBORHOOD_QUERY = gql`
  query Neighborhood($uuid: ID!) {
    neighborhood(uuid: $uuid)
      @rest(type: "Neighborhood", path: "/admin/neighborhoods/{args.uuid}") {
      uuid
      name
      city @type(name: "City") {
        uuid
        name
      }
    }
  }
`

export const CREATE_NEIGHBORHOOD_MUTATION = gql`
  mutation CreateNeighborhood($input: CreateNeighborhoodInput!) {
    createNeighborhood(input: $input)
      @rest(
        type: "Neighborhood"
        path: "/admin/neighborhoods"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const UPDATE_NEIGHBORHOOD_MUTATION = gql`
  mutation UpdateNeighborhood($uuid: ID!, $input: UpdateNeighborhoodInput!) {
    updateNeighborhood(uuid: $uuid, input: $input)
      @rest(
        type: "Neighborhood"
        path: "/admin/neighborhoods/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_NEIGHBORHOOD_MUTATION = gql`
  mutation DestroyNeighborhood($uuid: ID!) {
    destroyNeighborhood(uuid: $uuid)
      @rest(
        type: "Neighborhood"
        path: "/admin/neighborhoods/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
