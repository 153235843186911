import { ComponentType, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'

export const Analytics: ComponentType = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.initialize('UA-160221618-1')
  }, [])

  useEffect(() => {
    ReactGA.pageview(location.pathname)
  }, [location.pathname])

  return null
}
