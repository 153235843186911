import gql from 'graphql-tag'

export const SERVICE_DETAIL_FRAGMENT = gql`
  fragment ServiceDetail on Service {
    uuid
    name
  }
`

export const SERVICES_QUERY = gql`
  query Services {
    services @rest(type: "ServicesList", path: "/admin/services") {
      total
      data @type(name: "Service") {
        ...ServiceDetail
      }
    }
  }
  ${SERVICE_DETAIL_FRAGMENT}
`

export const SERVICE_QUERY = gql`
  query Service($uuid: ID!) {
    service(uuid: $uuid)
      @rest(type: "Service", path: "/admin/services/{args.uuid}") {
      uuid
      name
    }
  }
`

export const CREATE_SERVICE_MUTATION = gql`
  mutation CreateService($input: CreateServiceInput!) {
    createService(input: $input)
      @rest(type: "Service", path: "/admin/services", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_SERVICE_MUTATION = gql`
  mutation UpdateService($uuid: ID!, $input: UpdateServiceInput!) {
    updateService(uuid: $uuid, input: $input)
      @rest(
        type: "Service"
        path: "/admin/services/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_SERVICE_MUTATION = gql`
  mutation DestroyService($uuid: ID!) {
    destroyService(uuid: $uuid)
      @rest(
        type: "Service"
        path: "/admin/services/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
