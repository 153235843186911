import gql from 'graphql-tag'
import { PROPERTY_DETAIL_FRAGMENT } from './properties'
import { USER_DETAIL_FRAGMENT } from './users'

export const LEAD_DETAIL_FRAGMENT = gql`
  fragment LeadDetail on Lead {
    uuid
    phone
    email
    name
    city
    status
    createdAt
    updatedAt
    agent @type(name: "User") {
      ...UserDetail
    }
    property @type(name: "Property") {
      ...PropertyDetail
    }
  }
  ${PROPERTY_DETAIL_FRAGMENT}
  ${USER_DETAIL_FRAGMENT}
`

export const LEADS_QUERY = gql`
  query Leads($query: Object!) {
    leads(query: $query)
      @rest(type: "LeadsList", path: "/admin/leads?{args.query}") {
      total
      data @type(name: "Lead") {
        ...LeadDetail
      }
    }
  }
  ${LEAD_DETAIL_FRAGMENT}
`

export const LEAD_QUERY = gql`
  query Lead($uuid: ID!) {
    lead(uuid: $uuid) @rest(type: "Lead", path: "/admin/leads/{args.uuid}") {
      ...LeadDetail
    }
  }
  ${LEAD_DETAIL_FRAGMENT}
`

export const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLead($uuid: ID!, $input: UpdateLeadInput!) {
    updateLead(uuid: $uuid, input: $input)
      @rest(type: "Lead", path: "/admin/leads/{args.uuid}", method: "POST") {
      ...LeadDetail
    }
  }
  ${LEAD_DETAIL_FRAGMENT}
`
