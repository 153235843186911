import gql from 'graphql-tag'
import { PROPERTY_MEDIA_DETAIL_FRAGMENT } from './propertiesMedias'

export const PROPERTY_PHOTO_QUERY = gql`
  query PropertyPhoto($uuid: ID!, $photoUuid: ID!) {
    propertyPhoto(uuid: $uuid, photoUuid: $photoUuid)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/photos/{args.photoUuid}"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const CREATE_PROPERTY_PHOTO_MUTATION = gql`
  mutation CreatePropertyPhoto($uuid: ID!, $input: CreatePropertyPhotoInput!) {
    createPropertyPhoto(uuid: $uuid, input: $input)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/photos"
        method: "POST"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const UPDATE_PROPERTY_PHOTO_MUTATION = gql`
  mutation UpdatePropertyPhoto(
    $uuid: ID!
    $photoUuid: ID!
    $input: UpdatePropertyPhotoInput!
  ) {
    updatePropertyPhoto(uuid: $uuid, photoUuid: $photoUuid, input: $input)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/photos/{args.photoUuid}"
        method: "POST"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`

export const DESTROY_PROPERTY_PHOTO_MUTATION = gql`
  mutation DestroyPropertyPhoto($uuid: ID!, $photoUuid: ID!) {
    destroyPropertyPhoto(uuid: $uuid, photoUuid: $photoUuid)
      @rest(
        type: "PropertyMedia"
        path: "/admin/properties/{args.uuid}/gallery/photos/{args.photoUuid}"
        method: "DELETE"
      ) {
      ...PropertyMediaDetail
    }
  }
  ${PROPERTY_MEDIA_DETAIL_FRAGMENT}
`
