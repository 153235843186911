import { useQuery } from '@apollo/react-hooks'
import { ComponentType, ReactElement } from 'react'
import { USERS_QUERY } from '~graphql'
import { UserDetailFragment, UsersQuery, UsersQueryVariables } from '~types'

export const UseUsers: ComponentType<{
  children: (users: UserDetailFragment[]) => ReactElement | null
}> = ({ children }) => {
  const { data } = useQuery<UsersQuery, UsersQueryVariables>(USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { query: {} },
  })
  return children(data?.users.data ?? [])
}
