import gql from 'graphql-tag'

export const DEVELOPMENT_DETAIL_FRAGMENT = gql`
  fragment DevelopmentDetail on Development {
    uuid
    name
    neighborhood @type(name: "Neighborhood") {
      uuid
      name
    }
  }
`

export const DEVELOPMENTS_QUERY = gql`
  query Developments($neighborhood: ID) {
    developments(neighborhood: $neighborhood)
      @rest(type: "DevelopmentsList", path: "/admin/developments?{args}") {
      total
      data @type(name: "Development") {
        ...DevelopmentDetail
      }
    }
  }
  ${DEVELOPMENT_DETAIL_FRAGMENT}
`

export const DEVELOPMENT_QUERY = gql`
  query Development($uuid: ID!) {
    development(uuid: $uuid)
      @rest(type: "Development", path: "/admin/developments/{args.uuid}") {
      ...DevelopmentDetail
    }
  }
  ${DEVELOPMENT_DETAIL_FRAGMENT}
`

export const CREATE_DEVELOPMENT_MUTATION = gql`
  mutation CreateDevelopment($input: CreateDevelopmentInput!) {
    createDevelopment(input: $input)
      @rest(type: "Development", path: "/admin/developments", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_DEVELOPMENT_MUTATION = gql`
  mutation UpdateDevelopment($uuid: ID!, $input: UpdateDevelopmentInput!) {
    updateDevelopment(uuid: $uuid, input: $input)
      @rest(
        type: "Development"
        path: "/admin/developments/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_DEVELOPMENT_MUTATION = gql`
  mutation DestroyDevelopment($uuid: ID!) {
    destroyDevelopment(uuid: $uuid)
      @rest(
        type: "Development"
        path: "/admin/developments/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
