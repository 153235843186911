import gql from 'graphql-tag'

export const FINANCING_DETAIL_FRAGMENT = gql`
  fragment FinancingDetail on Financing {
    uuid
    name
  }
`

export const FINANCINGS_QUERY = gql`
  query Financings {
    financings @rest(type: "FinancingsList", path: "/admin/financings") {
      total
      data @type(name: "Financing") {
        ...FinancingDetail
      }
    }
  }
  ${FINANCING_DETAIL_FRAGMENT}
`

export const FINANCING_QUERY = gql`
  query Financing($uuid: ID!) {
    financing(uuid: $uuid)
      @rest(type: "Financing", path: "/admin/financings/{args.uuid}") {
      uuid
      name
    }
  }
`

export const CREATE_FINANCING_MUTATION = gql`
  mutation CreateFinancing($input: CreateFinancingInput!) {
    createFinancing(input: $input)
      @rest(type: "Financing", path: "/admin/financings", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_FINANCING_MUTATION = gql`
  mutation UpdateFinancing($uuid: ID!, $input: UpdateFinancingInput!) {
    updateFinancing(uuid: $uuid, input: $input)
      @rest(
        type: "Financing"
        path: "/admin/financings/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_FINANCING_MUTATION = gql`
  mutation DestroyFinancing($uuid: ID!) {
    destroyFinancing(uuid: $uuid)
      @rest(
        type: "Financing"
        path: "/admin/financings/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
