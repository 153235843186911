import gql from 'graphql-tag'

export const CREATE_BUG_REPORT_MUTATION = gql`
  mutation CreateBugReport($input: CreateBugReportInput!) {
    createBugReport(input: $input)
      @rest(type: "BugReport", path: "/admin/support/send", method: "POST") {
      uuid
    }
  }
`
