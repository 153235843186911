import get from 'lodash/get'
import set from 'lodash/set'
import * as Yup from 'yup'

interface ValidationError {
  message: string
  path: string[]
}

export const graphqlErrorToValidations = (error: any) => {
  const validationErrors: ValidationError[] =
    error?.networkError?.result.errors ?? []

  const touched = validationErrors.reduce(
    (touched, validationError) => set(touched, validationError.path, true),
    {},
  )

  const errors = validationErrors.reduce(
    (errors, validationError) =>
      set(errors, validationError.path, validationError.message),
    {},
  )

  const schema = validationErrors.reduce((errors, verror) => {
    const deep = verror.path.length > 1
    const path = deep
      ? verror.path.slice(0, verror.path.length - 1)
      : verror.path
    const field = get(errors, path)

    if (field) {
      set(
        errors,
        path,
        field.shape({
          [verror.path[verror.path.length - 1]]: Yup.string().required(
            verror.message,
          ),
        }),
      )
    } else {
      if (deep) {
        set(
          errors,
          path,
          Yup.object().shape({
            [verror.path[verror.path.length - 1]]: Yup.string().required(
              verror.message,
            ),
          }),
        )
      } else {
        if (verror.message.indexOf('is not allowed to be empty') != -1) {
          set(errors, path, Yup.string().required(verror.message))
        }
        else if (verror.message.indexOf('length must be at least 50 characters long') != -1) {
          set(errors, path, Yup.string().min(50, verror.message))
        }
        else if (verror.message.indexOf('length must be less than or equal to 60 characters long') >= -1) {
          set(errors, path, Yup.string().max(60, verror.message))
        }
        else if (verror.message.indexOf('length must be less than or equal to 160 characters long') >= -1) {
          set(errors, path, Yup.string().max(160, verror.message))
        }
        else {
          set(errors, path, Yup.string().required(verror.message))
        }
      }
    }

    return errors
  }, {})

  return { touched, errors, schema: Yup.object().shape(schema) }
}
