export const getActiveLabelBySelect = (id: string) => {
  const selectElement = document.getElementById(id) as HTMLSelectElement

  if (selectElement) {
    const optionElement = selectElement.options[selectElement.selectedIndex]

    if (optionElement.value) {
      return optionElement.text
    }
  }

  return ''
}
