import gql from 'graphql-tag'
import { USER_DETAIL_FRAGMENT } from './users'

export const AUTH_LOGIN_MUTATION = gql`
  mutation AuthLogin($input: LoginInput!) {
    login(input: $input)
      @rest(type: "LoginPayload", path: "/admin/users/login", method: "POST") {
      jwt
      user @type(name: "User") {
        ...UserDetail
      }
    }
  }
  ${USER_DETAIL_FRAGMENT}
`
