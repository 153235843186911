import { types } from 'mobx-state-tree'

export const UploadStat = types
  .model('UploadStat', {
    id: types.union(types.number, types.string),
    loading: types.optional(types.boolean, false),
    total: types.optional(types.number, 0),
    completed: types.optional(types.number, 0),
  })
  .views(self => ({
    get progress() {
      return (self.completed / self.total) * 100
    },
  }))
  .actions(self => ({
    start(total: number) {
      self.loading = true
      self.total = total
    },
    setCompleted(completed: number) {
      self.completed = completed
    },
    end() {
      self.loading = false
      self.total = 0
      self.completed = 0
    },
  }))
