import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import React, { ComponentType } from 'react'
import { Sortings } from '~models'

export interface TableSortOption {
  key: string
  label: string
  className?: string
  span?: number
}

const Sort = observer<{
  sortings: Instance<typeof Sortings>
  option: TableSortOption
}>(({ sortings, option }) => {
  return (
    <th
      className={cx(
        'text-nowrap py-4 cursor-pointer text-uppercase',
        option.className,
        {
          'text-primary': sortings.has(option.key),
          'text-muted': !sortings.has(option.key),
        },
      )}
      style={{ height: 65 }}
      colSpan={option.span}
      onClick={() => sortings.sort(option.key)}
    >
      <small className="font-weight-bold">
        {option.label} {sortings.desc(option.key) && '▴'}{' '}
        {sortings.asc(option.key) && '▾'}
      </small>
    </th>
  )
})

export const TableSort: ComponentType<{
  sortings: Instance<typeof Sortings>
  options: (TableSortOption | null)[]
}> = ({ sortings, options }) => {
  return (
    <thead>
      <tr>
        {options.map((option, index) =>
          option ? (
            <Sort key={option.key} sortings={sortings} option={option} />
          ) : (
            <th key={index} />
          ),
        )}
      </tr>
    </thead>
  )
}
