import gql from 'graphql-tag'

export const CATEGORY_DETAIL_FRAGMENT = gql`
  fragment CategoryDetail on Category {
    uuid
    name
    pluralName
    position
  }
`

export const CATEGORIES_QUERY = gql`
  query Categories {
    categories @rest(type: "CategoriesList", path: "/admin/categories") {
      total
      data @type(name: "Category") {
        ...CategoryDetail
      }
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const CATEGORY_QUERY = gql`
  query Category($uuid: ID!) {
    category(uuid: $uuid)
      @rest(type: "Category", path: "/admin/categories/{args.uuid}") {
      ...CategoryDetail
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input)
      @rest(type: "Category", path: "/admin/categories", method: "POST") {
      ...CategoryDetail
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategory($uuid: ID!, $input: UpdateCategoryInput!) {
    updateCategory(uuid: $uuid, input: $input)
      @rest(
        type: "Category"
        path: "/admin/categories/{args.uuid}"
        method: "POST"
      ) {
      ...CategoryDetail
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const UPDATE_CATEGORY_POSITION_MUTATION = gql`
  mutation UpdateCategoryPosition(
    $uuid: ID!
    $input: UpdateCategoryPositionInput!
  ) {
    updateCategoryPosition(uuid: $uuid, input: $input)
      @rest(
        type: "CategoriesList"
        path: "/admin/categories/{args.uuid}/position"
        method: "POST"
      ) {
      total
      data @type(name: "Category") {
        ...CategoryDetail
      }
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`

export const DESTROY_CATEGORY_MUTATION = gql`
  mutation DestroyCategory($uuid: ID!) {
    destroyCategory(uuid: $uuid)
      @rest(
        type: "Category"
        path: "/admin/categories/{args.uuid}"
        method: "DELETE"
      ) {
      ...CategoryDetail
    }
  }
  ${CATEGORY_DETAIL_FRAGMENT}
`
