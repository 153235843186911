import styled from '@emotion/styled'
import React, { ComponentType } from 'react'
import { Header } from './Header'
import { Sidebar } from './Sidebar'

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`

const Body = styled.div`
  flex: 1;
  padding-left: 240px;
  background: #f5f6f9;
`

export const Layout: ComponentType = ({ children }) => {
  return (
    <Container>
      <Sidebar />
      <Body>
        <Header />
        {children}
      </Body>
    </Container>
  )
}
