import { types } from 'mobx-state-tree'
import { Dashboard } from './Dashboard'
import { Leads } from './Leads'
import { Properties } from './Properties'
import { Users } from './Users'

export const Store = types
  .model('Store', {
    dashboard: types.optional(Dashboard, () => Dashboard.create()),
    properties: types.optional(Properties, () => Properties.create()),
    leads: types.optional(Leads, () => Leads.create()),
    users: types.optional(Users, () => Users.create()),
  })
  .actions(self => ({
    clean() {
      self.dashboard = Dashboard.create()
      self.properties = Properties.create()
      self.leads = Leads.create()
      self.users = Users.create()
    },
  }))
