import gql from 'graphql-tag'

export const CITY_DETAIL_FRAGMENT = gql`
  fragment CityDetail on City {
    uuid
    name
  }
`

export const CITIES_QUERY = gql`
  query Cities {
    cities @rest(type: "CititesList", path: "/admin/cities") {
      total
      data @type(name: "City") {
        ...CityDetail
      }
    }
  }
  ${CITY_DETAIL_FRAGMENT}
`

export const CITY_QUERY = gql`
  query City($uuid: ID!) {
    city(uuid: $uuid) @rest(type: "City", path: "/admin/cities/{args.uuid}") {
      uuid
      name
    }
  }
`

export const CREATE_CITY_MUTATION = gql`
  mutation CreateCity($input: CreateCityInput!) {
    createCity(input: $input)
      @rest(type: "City", path: "/admin/cities", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_CITY_MUTATION = gql`
  mutation UpdateCity($uuid: ID!, $input: UpdateCityInput!) {
    updateCity(uuid: $uuid, input: $input)
      @rest(type: "City", path: "/admin/cities/{args.uuid}", method: "POST") {
      uuid
      name
    }
  }
`

export const DESTROY_CITY_MUTATION = gql`
  mutation DestroyCity($uuid: ID!) {
    destroyCity(uuid: $uuid)
      @rest(type: "City", path: "/admin/cities/{args.uuid}", method: "DELETE") {
      uuid
    }
  }
`
