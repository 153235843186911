import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Input } from 'reactstrap'
import SearchIcon from '~/images/search-icon.svg'

import useReactRouter from 'use-react-router'
import { useStore } from '~hooks'
import styled from '@emotion/styled'

const Icon = styled(SearchIcon)`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`

export const Search = observer(() => {
  const store = useStore()
  const { history } = useReactRouter()
  const [search, setSearch] = useState(store.properties.search)

  return (
    <div className="d-flex align-items-center">
      <form
        className="position-relative"
        onSubmit={event => {
          event.preventDefault()
          store.properties.setSearch(search)
          history.push('/properties')
        }}
      >
        <Icon style={{ opacity: store.properties.search ? 0.6 : 0.4 }} />
        <Input
          placeholder="Busca propiedades por código"
          style={{ paddingLeft: 38, minWidth: 268 }}
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </form>
      {store.properties.search && (
        <button
          className="btn btn-primary ml-2"
          type="button"
          onClick={() => {
            setSearch('')
            store.properties.setSearch('')
          }}
        >
          Limpiar búsqueda
        </button>
      )}
    </div>
  )
})
