import gql from 'graphql-tag'

export const AMENITY_DETAIL_FRAGMENT = gql`
  fragment AmenityDetail on Amenity {
    uuid
    name
  }
`

export const AMENITIES_QUERY = gql`
  query Amenities {
    amenities @rest(type: "AmenitiesList", path: "/admin/amenities") {
      total
      data @type(name: "Amenity") {
        ...AmenityDetail
      }
    }
  }
  ${AMENITY_DETAIL_FRAGMENT}
`

export const AMENITY_QUERY = gql`
  query Amenity($uuid: ID!) {
    amenity(uuid: $uuid)
      @rest(type: "Amenity", path: "/admin/amenities/{args.uuid}") {
      uuid
      name
    }
  }
`

export const CREATE_AMENITY_MUTATION = gql`
  mutation CreateAmenity($input: CreateAmenityInput!) {
    createAmenity(input: $input)
      @rest(type: "Amenity", path: "/admin/amenities", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_AMENITY_MUTATION = gql`
  mutation UpdateAmenity($uuid: ID!, $input: UpdateAmenityInput!) {
    updateAmenity(uuid: $uuid, input: $input)
      @rest(
        type: "Amenity"
        path: "/admin/amenities/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_AMENITY_MUTATION = gql`
  mutation DestroyAmenity($uuid: ID!) {
    destroyAmenity(uuid: $uuid)
      @rest(
        type: "Amenity"
        path: "/admin/amenities/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
