import React, { ComponentType } from 'react'
import { Button, Input, InputProps } from 'reactstrap'

export const ButtonCheck: ComponentType<InputProps & { label: string }> = ({
  label,
  ...props
}) => {
  return (
    <Button
      tag="label"
      color={props.checked ? 'primary' : 'secondary'}
      outline={!props.checked}
      style={{ flex: 1 }}
      className="cursor-pointer"
    >
      {label}
      <Input {...props} style={{ display: 'none' }} />
    </Button>
  )
}
