import { useMutation } from '@apollo/react-hooks'
import styled from '@emotion/styled'
import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import useReactRouter from 'use-react-router'
import PlusIcon from '~/images/plus-icon.svg'
import { Thumbnail } from '~components/Thumbnail'
import { CREATE_PROPERTY_MUTATION } from '~graphql'
import { useStore, useViewer } from '~hooks'
import { CreatePropertyMutation, CreatePropertyMutationVariables } from '~types'
import { Search } from './Search'

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1020;
  height: 70px;
`

const Separator = styled.div`
  width: 1px;
  background: #e5e8ec;
`

const CreateMenuToggle = styled(DropdownToggle)`
  cursor: pointer;

  .icon path {
    fill: #2d79c3;
  }
`

const UserMenuToggle = styled(DropdownToggle)`
  cursor: pointer;
`

export const Header = observer(() => {
  const store = useStore()
  const { viewer } = useViewer()
  const { history } = useReactRouter()

  const [createProperty, createPropertyResult] = useMutation<
    CreatePropertyMutation,
    CreatePropertyMutationVariables
  >(CREATE_PROPERTY_MUTATION, {
    variables: {
      input: {},
    },
    onCompleted: ({ createProperty }) => {
      history.push(`/properties/${createProperty.uuid}/update`)
    },
  })

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <div
              className="d-flex justify-content-between"
              style={{ height: 70 }}
            >
              <Search />
              <div className="d-flex">
                <UncontrolledDropdown className="d-flex">
                  <CreateMenuToggle
                    tag="div"
                    className="px-4 d-flex align-items-center justify-content-center"
                  >
                    <PlusIcon className="icon" />
                  </CreateMenuToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      disabled={createPropertyResult.loading}
                      onClick={event => {
                        event.preventDefault()
                        createProperty()
                      }}
                    >
                      Nueva propiedad
                    </DropdownItem>
                    <DropdownItem tag={Link} to="/users/create">
                      Nuevo agente
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Separator />
                <UncontrolledDropdown className="d-flex">
                  <UserMenuToggle
                    tag="div"
                    className="pl-4 d-flex align-items-center justify-content-center text-primary"
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        {viewer?.firstName} {viewer?.lastName.charAt(0)}.
                      </div>
                      <Thumbnail
                        className="rounded-circle ml-3"
                        width={40}
                        height={40}
                        url={viewer?.avatar?.thumbnailUrl}
                      />
                    </div>
                  </UserMenuToggle>
                  <DropdownMenu right>
                    {viewer && (
                      <Fragment>
                        <DropdownItem
                          tag={Link}
                          to="/properties"
                          title="Ver tus propiedades"
                          onClick={() =>
                            store.properties.filters.save({
                              managers: [viewer.uuid],
                            })
                          }
                        >
                          Ver tus propiedades
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          to={`/users/${viewer.uuid}`}
                          title="Ver tu perfil"
                        >
                          Ver tu perfil
                        </DropdownItem>
                      </Fragment>
                    )}
                    <DropdownItem tag={Link} to="/logout" title="Cerrar sesión">
                      Cerrar sesión
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
})
