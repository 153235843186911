import { ApolloError } from 'apollo-client'
import React, { ComponentType, Fragment } from 'react'
import { Alert, Button, Input, ModalBody, ModalFooter } from 'reactstrap'
import { getApolloErrorMessage } from '~utils'

interface Props {
  name: string
  label: string
  loading?: boolean
  error?: ApolloError
  onCancel: () => void
  onSubmit: () => void
}

export const DestroyForm: ComponentType<Props> = ({
  name,
  label,
  loading,
  error,
  onCancel,
  onSubmit,
}) => {
  return (
    <Fragment>
      <ModalBody>
        {error && (
          <div className="mb-2">
            <Alert color="danger">{getApolloErrorMessage(error)}</Alert>
          </div>
        )}
        <h6 className="text-danger mb-4">Eliminar {label}</h6>
        <Input className="mb-4" defaultValue={name} disabled readOnly />
        <p className="text-muted">
          Eliminar <strong>{name}</strong> como {label}{' '}
          <span className="text-danger">esta una acción no es reversible</span>.
        </p>
        <p className="text-muted mb-0">
          ¿En verdad quieres eliminar <strong>{name}</strong>?
        </p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button
          type="button"
          color="link"
          disabled={loading}
          onClick={() => onCancel()}
        >
          <small className="text-muted text-uppercase">No, Cancelar</small>
        </Button>
        <Button
          type="button"
          color="danger"
          disabled={loading}
          onClick={() => onSubmit()}
        >
          Sí, eliminar {label}
        </Button>
      </ModalFooter>
    </Fragment>
  )
}
