import React, { ComponentType, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

export const CopyText: ComponentType<{ text: string }> = ({ text }) => {
  const [copied, setCopied] = useState(false)

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
      }}
    >
      <small className="handler text-muted text-uppercase cursor-pointer">
        {copied ? 'Copiado' : 'Copiar'}
      </small>
    </CopyToClipboard>
  )
}
