import gql from 'graphql-tag'

export const INVITATION_QUERY = gql`
  query Invitation($token: String!) {
    invitation(token: $token)
      @rest(type: "Invitation", path: "/admin/invitations/{args.token}") {
      email
    }
  }
`

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($token: String!, $input: AcceptInvitationInput!) {
    acceptInvitation(token: $token, input: $input)
      @rest(
        type: "String"
        path: "/admin/invitations/{args.token}/accept"
        method: "POST"
      ) {
      jwt
      user @type(name: "User") {
        uuid
        firstName
        lastName
        email
        role
      }
    }
  }
`

export const RESEND_INVITATION_MUTATION = gql`
  mutation ResendInvitation($uuid: ID!, $input: Object!) {
    resendInvitation(uuid: $uuid, input: $input)
      @rest(
        type: "String"
        path: "/admin/invitations/{args.uuid}/resend"
        method: "POST"
      ) {
      ok
    }
  }
`
