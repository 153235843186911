import gql from 'graphql-tag'

export const ZONE_DETAIL_FRAGMENT = gql`
  fragment ZoneDetail on Zone {
    uuid
    name
    position
  }
`

export const ZONES_QUERY = gql`
  query Zones {
    zones @rest(type: "Zones", path: "/admin/zones") {
      total
      data @type(name: "Zone") {
        ...ZoneDetail
      }
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const ZONE_QUERY = gql`
  query Zone($uuid: ID!) {
    zone(uuid: $uuid) @rest(type: "Zone", path: "/admin/zones/{args.uuid}") {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const CREATE_ZONE_MUTATION = gql`
  mutation CreateZone($input: CreateZoneInput!) {
    createZone(input: $input)
      @rest(type: "Zone", path: "/admin/zones", method: "POST") {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const UPDATE_ZONE_MUTATION = gql`
  mutation UpdateZone($uuid: ID!, $input: UpdateZoneInput!) {
    updateZone(uuid: $uuid, input: $input)
      @rest(type: "Zone", path: "/admin/zones/{args.uuid}", method: "POST") {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const UPDATE_ZONE_POSITION_MUTATION = gql`
  mutation UpdateZonePosition($uuid: ID!, $input: UpdateZonePositionInput!) {
    updateZonePosition(uuid: $uuid, input: $input)
      @rest(
        type: "Zone"
        path: "/admin/zones/{args.uuid}/position"
        method: "POST"
      ) {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`

export const DESTROY_ZONE_MUTATION = gql`
  mutation DestroyZone($uuid: ID!) {
    destroyZone(uuid: $uuid)
      @rest(type: "Zone", path: "/admin/zones/{args.uuid}", method: "DELETE") {
      ...ZoneDetail
    }
  }
  ${ZONE_DETAIL_FRAGMENT}
`
