import { useQuery } from '@apollo/react-hooks'
import { ComponentType, ReactElement } from 'react'
import { CATEGORIES_QUERY } from '~graphql'
import {
  CategoriesQuery,
  CategoriesQueryVariables,
  CategoryDetailFragment,
} from '~types'
import { sortByPosition } from '~utils'

export const UseCategories: ComponentType<{
  children: (categories: CategoryDetailFragment[]) => ReactElement | null
}> = ({ children }) => {
  const { data } = useQuery<CategoriesQuery, CategoriesQueryVariables>(
    CATEGORIES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  )
  return children(sortByPosition(data?.categories.data ?? []))
}
