import gql from 'graphql-tag'

export const PROMOTION_DETAIL_FRAGMENT = gql`
  fragment PromotionDetail on Promotion {
    uuid
    name
  }
`

export const PROMOTIONS_QUERY = gql`
  query Promotions {
    promotions @rest(type: "PromotionsList", path: "/admin/promotions") {
      total
      data @type(name: "Promotion") {
        ...PromotionDetail
      }
    }
  }
  ${PROMOTION_DETAIL_FRAGMENT}
`

export const PROMOTION_QUERY = gql`
  query Promotion($uuid: ID!) {
    promotion(uuid: $uuid)
      @rest(type: "Promotion", path: "/admin/promotions/{args.uuid}") {
      ...PromotionDetail
    }
  }
  ${PROMOTION_DETAIL_FRAGMENT}
`

export const CREATE_PROMOTION_MUTATION = gql`
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input)
      @rest(type: "Promotion", path: "/admin/promotions", method: "POST") {
      ...PromotionDetail
    }
  }
  ${PROMOTION_DETAIL_FRAGMENT}
`

export const UPDATE_PROMOTION_MUTATION = gql`
  mutation UpdatePromotion($uuid: ID!, $input: UpdatePromotionInput!) {
    updatePromotion(uuid: $uuid, input: $input)
      @rest(
        type: "Promotion"
        path: "/admin/promotions/{args.uuid}"
        method: "POST"
      ) {
      ...PromotionDetail
    }
  }
  ${PROMOTION_DETAIL_FRAGMENT}
`

export const DESTROY_PROMOTION_MUTATION = gql`
  mutation DestroyPromotion($uuid: ID!) {
    destroyPromotion(uuid: $uuid)
      @rest(
        type: "Promotion"
        path: "/admin/promotions/{args.uuid}"
        method: "DELETE"
      ) {
      ...PromotionDetail
    }
  }
  ${PROMOTION_DETAIL_FRAGMENT}
`
