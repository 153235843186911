import cleanDeep from 'clean-deep'
import { merge } from 'merge-anything'
import {
  applySnapshot,
  getParent,
  getSnapshot,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { Users } from './Users'

export const UsersFilters = types
  .model('UsersFilters', {
    status: types.maybe(types.string),
    team: types.array(types.string),
  })
  .views(self => ({
    format() {
      return cleanDeep(getSnapshot(self))
    },
  }))
  .actions(self => ({
    save(values: SnapshotIn<typeof self>) {
      applySnapshot(self, merge(getSnapshot(self), values))
      getParent<typeof Users>(self).setPage(1)
    },
  }))
