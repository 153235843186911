import { types } from 'mobx-state-tree'
import { Sortings } from './Sortings'
import { UsersFilters } from './UsersFilters'

export const Users = types
  .model('Users', {
    page: types.optional(types.number, 1),
    filters: types.optional(UsersFilters, () => UsersFilters.create()),
    sortings: types.optional(Sortings, () => Sortings.create()),
  })
  .actions(self => ({
    setPage(page: number) {
      self.page = page
    },
  }))
