import cleanDeep from 'clean-deep'
import { merge } from 'merge-anything'
import {
  applySnapshot,
  getParent,
  getSnapshot,
  SnapshotIn,
  types,
} from 'mobx-state-tree'
import { Properties } from './Properties'

const AddressFilter = types.model('AddressFilter', {
  city: types.array(types.string),
  neighborhood: types.array(types.string),
  development: types.array(types.string),
})

const SaleFilters = types.model('SaleFilters', {
  'price>': types.optional(types.union(types.string, types.number), ''),
  'price<': types.optional(types.union(types.string, types.number), ''),
  financings: types.array(types.string),
})

const RentFilters = types.model('RentFilters', {
  'monthPrice>': types.optional(types.union(types.string, types.number), ''),
  'monthPrice<': types.optional(types.union(types.string, types.number), ''),
})

const AreaFilter = types.model('AreaFilter', {
  'total>': types.optional(types.union(types.string, types.number), ''),
  'total<': types.optional(types.union(types.string, types.number), ''),
})

const PlacesFilter = types.model('PlacesFilter', {
  floors: types.optional(types.union(types.string, types.number), ''),
  rooms: types.optional(types.union(types.string, types.number), ''),
  bathrooms: types.optional(types.union(types.string, types.number), ''),
  halfBathrooms: types.optional(types.union(types.string, types.number), ''),
  parkingSpacesCeiling: types.optional(
    types.union(types.string, types.number),
    '',
  ),
  parkingSpacesNoCeiling: types.optional(
    types.union(types.string, types.number),
    '',
  ),
})

export const PropertiesFilters = types
  .model('PropertiesFilters', {
    isArchived: types.maybe(types.string),
    visible: types.maybe(types.string),
    promoted: types.maybe(types.string),
    availability: types.optional(types.string, ''),
    promotion: types.array(types.string),
    managers: types.array(types.string),
    categories: types.array(types.string),
    amenities: types.array(types.string),
    services: types.array(types.string),
    furnishings: types.array(types.string),
    zone: types.array(types.string),
    role: types.maybe(types.string),
    address: types.optional(AddressFilter, () => AddressFilter.create()),
    sale: types.optional(SaleFilters, () => SaleFilters.create()),
    rent: types.optional(RentFilters, () => RentFilters.create()),
    area: types.optional(AreaFilter, () => AreaFilter.create()),
    places: types.optional(PlacesFilter, () => PlacesFilter.create()),
  })
  .views(self => ({
    format() {
      return cleanDeep(getSnapshot(self))
    },
  }))
  .actions(self => ({
    save(values: SnapshotIn<typeof self>) {
      applySnapshot(self, merge(getSnapshot(self), values))
      getParent<typeof Properties>(self).setPage(1)
    },
  }))
