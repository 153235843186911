import gql from 'graphql-tag'

export const FURNISHING_DETAIL_FRAGMENT = gql`
  fragment FurnishingDetail on Furnishing {
    uuid
    name
  }
`

export const FURNISHINGS_QUERY = gql`
  query Furnishings {
    furnishings @rest(type: "FurnishingsList", path: "/admin/furnishings") {
      total
      data @type(name: "Furnishing") {
        ...FurnishingDetail
      }
    }
  }
  ${FURNISHING_DETAIL_FRAGMENT}
`

export const FURNISHING_QUERY = gql`
  query Furnishing($uuid: ID!) {
    furnishing(uuid: $uuid)
      @rest(type: "Furnishing", path: "/admin/furnishings/{args.uuid}") {
      uuid
      name
    }
  }
`

export const CREATE_FURNISHING_MUTATION = gql`
  mutation CreateFurnishing($input: CreateFurnishingInput!) {
    createFurnishing(input: $input)
      @rest(type: "Furnishing", path: "/admin/furnishings", method: "POST") {
      uuid
      name
    }
  }
`

export const UPDATE_FURNISHING_MUTATION = gql`
  mutation UpdateFurnishing($uuid: ID!, $input: UpdateFurnishingInput!) {
    updateFurnishing(uuid: $uuid, input: $input)
      @rest(
        type: "Furnishing"
        path: "/admin/furnishings/{args.uuid}"
        method: "POST"
      ) {
      uuid
      name
    }
  }
`

export const DESTROY_FURNISHING_MUTATION = gql`
  mutation DestroyFurnishing($uuid: ID!) {
    destroyFurnishing(uuid: $uuid)
      @rest(
        type: "Furnishing"
        path: "/admin/furnishings/{args.uuid}"
        method: "DELETE"
      ) {
      uuid
      name
    }
  }
`
