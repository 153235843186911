import { useQuery } from '@apollo/react-hooks'
import { ComponentType, ReactElement } from 'react'
import { NEIGHBORHOODS_QUERY } from '~graphql'
import {
  NeighborhoodDetailFragment,
  NeighborhoodsQuery,
  NeighborhoodsQueryVariables,
} from '~types'
import { sortByName } from '~utils'

export const UseNeighborhoods: ComponentType<{
  city?: string
  children: (neighborhoods: NeighborhoodDetailFragment[]) => ReactElement | null
}> = ({ city, children }) => {
  const { data } = useQuery<NeighborhoodsQuery, NeighborhoodsQueryVariables>(
    NEIGHBORHOODS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { city },
    },
  )
  return children(
    data && data.neighborhoods ? sortByName(data.neighborhoods.data) : [],
  )
}
