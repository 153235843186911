import { types } from 'mobx-state-tree'
import { LeadsFilters } from './LeadsFilters'
import { Sortings } from './Sortings'

export const Leads = types
  .model('Leads', {
    page: types.optional(types.number, 1),
    filters: types.optional(LeadsFilters, () => LeadsFilters.create()),
    sortings: types.optional(Sortings, () => Sortings.create()),
  })
  .actions(self => ({
    setPage(page: number) {
      self.page = page
    },
  }))
