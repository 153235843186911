import React, { ComponentType } from 'react'
import { Badge } from 'reactstrap'

export const PropertyBadge: ComponentType<{ role: string }> = ({ role }) => {
  return (
    <div className="inline-flex">
      {role.includes('sale') && (
        <Badge color="primary" className="mx-1">
          V
        </Badge>
      )}
      {role.includes('rent') && (
        <Badge color="success" className="mx-1">
          R
        </Badge>
      )}
    </div>
  )
}
