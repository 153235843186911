import cleanDeep from 'clean-deep'
import { getSnapshot, types } from 'mobx-state-tree'

export const Sortings = types
  .model('Sortings', {
    visible: types.optional(types.boolean, false),
    values: types.array(types.string),
  })
  .views(self => ({
    has(key: string) {
      return self.values.map(value => value.replace('-', '')).includes(key)
    },
    desc(key: string) {
      return self.values.includes(key)
    },
    asc(key: string) {
      return self.values.includes(`-${key}`)
    },
    format() {
      return cleanDeep({ sort: getSnapshot(self.values) })
    },
  }))
  .actions(self => ({
    toggleVisible() {
      const visible = !self.visible

      self.visible = visible

      if (!visible) {
        self.values.replace([])
      }
    },
    sort(key: string) {
      const index = self.values
        .map(value => value.replace('-', ''))
        .findIndex(value => value === key)

      if (index === -1) {
        self.values.push(key)
      } else {
        if (self.values[index].charAt(0) === '-') {
          self.values.splice(index, 1)
        } else {
          self.values[index] = `-${key}`
        }
      }
    },
  }))
