import gql from 'graphql-tag'

export const PROPERTY_MEDIA_DETAIL_FRAGMENT = gql`
  fragment PropertyMediaDetail on PropertyMedia {
    uuid
    type
    title
    position
    publicUrl
    secureUrl
    thumbnailUrl
  }
`

export const UPDATE_PROPERTY_MEDIA_POSITION_MUTATION = gql`
  mutation UpdatePropertyMediaPosition(
    $uuid: ID!
    $mediaUuid: ID!
    $input: UpdatePropertyMediaPositionInput!
  ) {
    updatePropertyMediaPosition(
      uuid: $uuid
      mediaUuid: $mediaUuid
      input: $input
    )
      @rest(
        type: "CategoriesList"
        path: "/admin/properties/{args.uuid}/gallery/{args.mediaUuid}/position"
        method: "POST"
      ) {
      uuid
    }
  }
`

export const DESTROY_PROPERTY_MEDIA = gql`
  mutation DestroyPropertyMedia($uuid: ID!) {
    destroyPropertyMedia(uuid: $uuid)
      @rest(
        type: "Boolean"
        path: "/admin/properties/{args.uuid}/gallery"
        method: "DELETE"
      ) {
      id
    }
  }
`
