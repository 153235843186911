import { ApolloProvider } from '@apollo/react-hooks'
import { LoadScript } from '@react-google-maps/api'
import es from 'date-fns/locale/es'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/set'
import { Settings as LuxonSettings } from 'luxon'
import { applySnapshot, onSnapshot } from 'mobx-state-tree'
import React, { ComponentType, useEffect, useRef, useState } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { StoreContext } from '~contexts'
import { client } from '~graphql'
import { Store } from '~models'
import { Navigation } from './Navigation'

registerLocale('es', es)
setDefaultLocale('es')

LuxonSettings.defaultLocale = 'es-MX'

export const Application: ComponentType = () => {
  const [ready, setReady] = useState(false)
  const { current: store } = useRef(Store.create())

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      global.store = store
    }

    const boostrap = async () => {
      try {
        const cache = localStorage.getItem('store')

        if (cache) {
          const obj = JSON.parse(cache)
          set(obj, 'properties.uploads', [])
          applySnapshot(store, obj)
        }
      } catch (err) {
        localStorage.removeItem('store')
      }

      onSnapshot(store, snapshot => {
        const snap = cloneDeep(snapshot)
        set(snap, 'properties.uploads', [])
        localStorage.setItem('store', JSON.stringify(snap))
      })

      setReady(true)
    }

    boostrap()
  }, [])

  if (ready) {
    return (
      <LoadScript
        googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}
        loadingElement={<div />}
      >
        <StoreContext.Provider value={store}>
          <ApolloProvider client={client}>
            <Navigation />
          </ApolloProvider>
        </StoreContext.Provider>
      </LoadScript>
    )
  }

  return null
}
