export const getCoordinatesByAddress = (address: string) => {
  const geocoder = new google.maps.Geocoder()

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        resolve([
          results[0].geometry.location.lng(),
          results[0].geometry.location.lat(),
        ])
      } else {
        reject(new Error(`No se pudo encontrar la ubicación ${address}`))
      }
    })
  })
}
