import gql from 'graphql-tag'

export const RETRIEVE_PASSWORD_MUTATION = gql`
  mutation RetrievePassword($input: RetrievePasswordInput!) {
    retrievePassword(input: $input)
      @rest(
        type: "RetrievePasswordPayload"
        path: "/admin/passwords/reset"
        method: "POST"
      ) {
      data
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $input: ResetPasswordInput!) {
    resetPassword(token: $token, input: $input)
      @rest(
        type: "ResetPasswordPayload"
        path: "/admin/passwords/set/{args.token}"
        method: "POST"
      ) {
      jwt
      user @type(name: "User") {
        uuid
        firstName
        lastName
        email
        role
      }
    }
  }
`
