import styled from '@emotion/styled'

export const Thumbnail = styled.div<{
  width?: number
  height?: number
  url?: string
  square?: boolean
  placeholder?: string
  position?: string
}>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '0px')};
  padding-bottom: ${({ height, square }) => {
    if (height) {
      return undefined
    } else if (square) {
      return '100%'
    } else {
      return '60%'
    }
  }};
  background-image: ${({ url, placeholder }) => {
    if (url) {
      return `url("${url}")`
    } else if (placeholder) {
      return `url("${placeholder}")`
    } else {
      return undefined
    }
  }};
  background-position: ${({ position }) => position || 'center center'};
  background-repeat: no-repeat;
  background-size: ${({ url }) => (url ? 'cover' : undefined)};
  background-color: #e9ebef;
`
