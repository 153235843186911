export const translations = {
  properties: {
    roles: {
      sale: 'Venta',
      rent: 'Renta',
      'sale-rent': 'Venta y Renta',
    },
  },
  users: {
    teams: {
      sales: 'Ventas',
      rents: 'Rentas',
      admins: 'Administración',
    },
    roles: {
      broker: 'Agente',
      manager: 'Manager',
      admin: 'Administrador',
    },
  },
  leads: {
    statuses: {
      interested: 'Cliente interesado',
      contacted: 'Contacto establecido',
      preInterview: 'Entrevista programada',
      qualified: 'Cliente calificado',
      proposal: 'Propuesta realizada',
      followUp: 'Seguimiento',
      negotiating: 'Oferta y negociación',
      reserved: 'Apartado',
      scheduled: 'Firma/Escritura programada',
      signed: 'Firmado/Escriturado',
      postSale: 'Seguimiento post-venta',
      completed: 'Proceso concluído',
      stalled: 'Detenida',
      lost: 'Perdida',
    },
  },
}
