import gql from 'graphql-tag'

export const PROPERTY_FILE_DETAIL_FRAGMENT = gql`
  fragment PropertyFileDetail on PropertyFile {
    uuid
    publicUrl
    secureUrl
  }
`

export const PROPERTY_FILE_QUERY = gql`
  query PropertyFile($uuid: ID!, $fileUuid: ID!) {
    propertyFile(uuid: $uuid, fileUuid: $fileUuid)
      @rest(
        type: "PropertyFile"
        path: "/admin/properties/{args.uuid}/files/{args.fileUuid}"
      ) {
      ...PropertyFileDetail
    }
  }
  ${PROPERTY_FILE_DETAIL_FRAGMENT}
`

export const CREATE_PROPERTY_FILE_MUTATION = gql`
  mutation CreatePropertyFile($uuid: ID!, $input: CreatePropertyFileInput!) {
    createPropertyFile(uuid: $uuid, input: $input)
      @rest(
        type: "PropertyFile"
        path: "/admin/properties/{args.uuid}/files"
        method: "POST"
        bodySerializer: "upload"
      ) {
      ...PropertyFileDetail
    }
  }
  ${PROPERTY_FILE_DETAIL_FRAGMENT}
`

export const DESTROY_PROPERTY_FILE_MUTATION = gql`
  mutation DestroyPropertyFile($propertyUuid: ID!, $fileUuid: ID!) {
    destroyPropertyFile(propertyUuid: $propertyUuid, fileUuid: $fileUuid)
      @rest(
        type: "PropertyFile"
        path: "/admin/properties/{args.propertyUuid}/files/{args.fileUuid}"
        method: "DELETE"
      ) {
      ...PropertyFileDetail
    }
  }
  ${PROPERTY_FILE_DETAIL_FRAGMENT}
`
