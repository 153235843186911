import get from 'lodash/get'
import React, {
  ComponentType,
  Fragment,
  lazy,
  Suspense,
  useEffect,
} from 'react'
import { useLocation } from 'react-router'
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
import { Layout } from '~/components/Layout'
import { useViewer } from '~hooks'
import { Analytics } from './Analytics'

const JWTValidation: ComponentType = ({ children }) => {
  const { viewer, error } = useViewer()

  if (get(error, 'networkError.response.status') === 401) {
    return <Redirect to="/logout" />
  }

  if (viewer?.uuid) {
    return <Fragment>{children}</Fragment>
  }

  return null
}

const ProtectedRoute: ComponentType<RouteProps> = props => {
  if (localStorage.getItem('token')) {
    return (
      <JWTValidation>
        <Layout>
          <Suspense fallback={null}>
            <Route {...props} />
          </Suspense>
        </Layout>
      </JWTValidation>
    )
  } else {
    return <Redirect to="/login" />
  }
}

const AnonymousRoute: ComponentType<RouteProps> = props => {
  if (localStorage.getItem('token')) {
    return <Redirect to="/" />
  } else {
    return <Route {...props} />
  }
}

const ScrollToTop: ComponentType = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const Dashboard = lazy(() => import('~views/Dashboard'))
const Invitations = lazy(() => import('~views/Invitations'))
const Leads = lazy(() => import('~views/Leads'))
const Login = lazy(() => import('~views/Login'))
const Logout = lazy(() => import('~views/Logout'))
const Passwords = lazy(() => import('~views/Passwords'))
const Properties = lazy(() => import('~views/Properties'))
const Settings = lazy(() => import('~views/Settings'))
const Support = lazy(() => import('~views/Support'))
const Users = lazy(() => import('~views/Users'))

export const Navigation: ComponentType = () => {
  return (
    <BrowserRouter>
      <Analytics />
      <ScrollToTop />
      <Suspense fallback={null}>
        <Switch>
          <ProtectedRoute path="/" component={Dashboard} exact />
          <ProtectedRoute path="/properties" component={Properties} />
          <ProtectedRoute path="/leads" component={Leads} />
          <ProtectedRoute path="/users" component={Users} />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/support" component={Support} />
          <AnonymousRoute path="/passwords" component={Passwords} />
          <AnonymousRoute path="/invitations" component={Invitations} />
          <Redirect from="/auth/login" to="/login" />
          <AnonymousRoute path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route render={() => <h2>Not Found</h2>} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}
