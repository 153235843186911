import { DateTime } from 'luxon'
import { types } from 'mobx-state-tree'

export const Dashboard = types
  .model('Dashboard', {
    role: types.maybe(types.string),
    startDate: types.optional(types.string, () => {
      return DateTime.utc()
        .minus({ week: 1 })
        .toSQLDate()
    }),
    endDate: types.optional(types.string, () => {
      return DateTime.utc().toSQLDate()
    }),
  })
  .views(self => ({
    query() {
      return {
        role: self.role,
        'date>=': self.startDate,
        'date<=': self.endDate,
        start: 0,
        limit: -1,
      }
    },
  }))
  .actions(self => ({
    setRole(role: string) {
      self.role = role
    },
    unsetRole() {
      self.role = undefined
    },
    setStartDate(date: string) {
      self.startDate = date
    },
    setEndDate(date: string) {
      self.endDate = date
    },
  }))
